
















































































import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { BvModalEvent } from 'bootstrap-vue';
import { MemberImage, UserImage } from '@/shared/gen/messages.pisa';
import ModalButton from '@/shared/components/modals/ModalButton.vue';
import SimpleModal from '@/shared/components/modals/SimpleModal.vue';
import ImageErrorModal from '@/shared/components/modals/ImageErrorModal.vue';
import UploadedImages from '@/shared/components/builder/UploadedImages.vue';
import MemberImages from '@/shared/components/builder/MemberImages.vue';
import { UploadError } from '@/shared/store/imageLibrary';

@Component({
  components: {
    ModalButton,
    SimpleModal,
    ImageErrorModal,
    UploadedImages,
    MemberImages,
  },
})
export default class ChangeImageSelectionModal extends Vue {
  $refs!: {
    modal: SimpleModal,
    errorModal: ImageErrorModal,
  }

  @Prop({ required: true }) selectedImages: UserImage[];

  @Prop({ default: () => [] }) selectedMemberImages: MemberImage[];

  @Prop({ default: () => [] }) disabledUserImageIds: String[];

  @Prop({ default: false, type: Boolean }) showMemberImages: boolean;

  @Prop({ default: 'uploaded-images-tab' }) defaultTab: string;

  @Getter('imageLibrary/getLibrary') imageLibrary: UserImage[];

  @Getter('recommendedAds/getMemberImages') memberImageLibrary: MemberImage[];

  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTouch') isTouch: boolean;

  @Getter('imageLibrary/getUploadErrors') errors: UploadError[];

  newSelectedImages: UserImage[] = [];

  newSelectedMemberImages: MemberImage[] = [];

  combinedImageLibrary: (UserImage|MemberImage)[] = [];

  handleModalShow() {
    this.newSelectedImages = this.selectedImages.slice();
    this.newSelectedMemberImages = this.selectedMemberImages.slice();
    const el = document.getElementById('uploaded-images');
    if (el && el.parentElement && el.parentElement.scrollTo) {
      el.parentElement.scrollTo(0, 0);
    }
    this.combinedImageLibrary = [];
    this.newSelectedImages.forEach((img) => this.combinedImageLibrary.push(img));
    this.newSelectedMemberImages.forEach((img) => this.combinedImageLibrary.push(img));
  }

  handleModalHidden(e: BvModalEvent) {
    if (e.trigger === 'login') {
      this.$root.$once('bv::modal::shown', (evt: BvModalEvent) => {
        if (evt.componentId === 'login-modal') {
          this.$root.$once('bv::modal::hidden', (event: BvModalEvent) => {
            if (event.componentId === 'login-modal') {
              this.$refs.modal.show();
            }
          });
        }
      });
    }
  }

  loadLibrary() {
    this.$store.dispatch('imageLibrary/loadLibrary');
  }

  @Watch('isLoggedInWithEmail')
  reloadLibrary(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.loadLibrary();
    }
  }

  get saveChangesButtonCopy() {
    return 'Confirm';
  }

  get saveChangesButtonDisabled() {
    if (this.showMemberImages) {
      return this.newSelectedImages.length === 0 && this.newSelectedMemberImages.length === 0;
    }
    return this.newSelectedImages.length === 0 && this.selectedImages.length === 0;
  }

  get selectedImageIds() {
    return this.newSelectedImages.map((i) => i.id);
  }

  get selectedMemberImageIds() {
    return this.newSelectedMemberImages.map((i) => i.id);
  }

  handleAddImage(id: string) {
    const image = this.imageLibrary.find((i) => i.id === id);
    if (image) {
      this.newSelectedImages = [image];
      this.newSelectedMemberImages = [];
      this.combinedImageLibrary = [image];
      this.changeCreativeImage();
    }
  }

  isUserImage(img: UserImage | MemberImage): boolean {
    if ('theme' in img) {
      return false;
    }
    return true;
  }

  handleRemoveImage(id: string) {
    this.newSelectedImages = this.newSelectedImages.filter((i) => i.id !== id);
    this.combinedImageLibrary = this.combinedImageLibrary.filter((i) => i.id !== id && this.isUserImage(i));
  }

  handleAddMemberImage(id: string) {
    const image = this.memberImageLibrary.find((i) => i.id === id);
    if (image) {
      this.newSelectedMemberImages = [image];
      this.newSelectedImages = [];
      this.combinedImageLibrary = [image];
      this.changeCreativeImage();
    }
  }

  handleRemoveMemberImage(id: string) {
    this.newSelectedMemberImages = this.newSelectedMemberImages.filter((i) => i.id !== id);
    this.combinedImageLibrary = this.combinedImageLibrary.filter((i) => i.id !== id && !this.isUserImage(i));
  }

  changeCreativeImage() {
    if ((this.selectedImages.length !== this.newSelectedImages.length)
      || (this.selectedMemberImages.length !== this.newSelectedMemberImages.length)
      || (this.selectedImages.map((i) => i.id).sort().join(',') !== this.newSelectedImages.map((i) => i.id).sort().join(','))
      || (this.selectedMemberImages.map((i) => i.id).sort().join(',') !== this.newSelectedMemberImages.map((i) => i.id).sort().join(','))) {
      this.$emit('set-image-changes', {
        userImages: this.newSelectedImages,
        memberImages: this.newSelectedMemberImages,
      });
    }
    this.$refs.modal.hide();
  }

  showErrorModal() {
    this.$refs.errorModal.show();
  }

  clearErrorModal() {
    this.$refs.errorModal.hide();
    this.$store.dispatch('imageLibrary/clearUploadErrors');
  }

  login() {
    this.$refs.modal.hide('login');
    this.$root.$emit('bv::show::modal', 'login-modal');
  }
}
