



























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IFacebookPage } from '@/shared/gen/messages.pisa';
import SocialProfileIcon from '@/shared/components/common/SocialProfileIcon.vue';

@Component({
  components: { SocialProfileIcon },
})
export default class SocialConnections extends Vue {
  @Prop({ default: 'Connect' })
  message: string;

  @Prop({ default: false })
  forceHighlight: boolean;

  @Prop({ default: null }) syncedPage: IFacebookPage;

  @Prop({ default: false, type: Boolean })
  listView: boolean;

  get hasSyncedPage() {
    return this.syncedPage && this.syncedPage.pageId;
  }

  get facebookPagePicture() {
    if (this.hasFacebookPagePicture) {
      return this.syncedPage.pictureUrl;
    }
    return '';
  }

  get facebookProfileName() {
    if (this.syncedPage.name) {
      return this.syncedPage.name;
    }
    return '';
  }

  get hasFacebookPagePicture() {
    return this.hasSyncedPage && this.syncedPage.pictureUrl;
  }

  get instagramPicture() {
    if (this.hasInstagramPicture) {
      return this.syncedPage.instagram!.pictureUrl;
    }
    return '';
  }

  get instagramProfileName() {
    if (this.hasSyncedPage && this.syncedPage.instagram && this.syncedPage.instagram.username) {
      return this.syncedPage.instagram.username;
    }
    return '';
  }

  get hasInstagramPicture() {
    return this.hasSyncedPage && this.syncedPage.instagram && this.syncedPage.instagram.pictureUrl;
  }

  get syncedInstagram() {
    if (this.syncedPage && this.syncedPage.instagram && this.syncedPage.instagram.pictureUrl) {
      return {
        pictureUrl: this.syncedPage.instagram.pictureUrl,
      };
    }
    return null;
  }

  syncFacebook() {
    this.$emit('click');
  }
}
