















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import SyncExistingFacebookPageModal from '@/shared/components/modals/social/SyncExistingFacebookPageModal.vue';
import SyncNewFacebookPageModal from '@/shared/components/modals/social/SyncNewFacebookPageModal.vue';
import { StoreFacebookPage } from '@/shared/store/facebook';

@Component({
  components: {
    SyncNewFacebookPageModal,
    SyncExistingFacebookPageModal,
  },
})
export default class SyncFacebookPageModals extends Vue {
  $refs!: {
    existingModal: any,
    newModal: any,
  };

  @Prop({ default: '' }) currentPageId: string;

  @Prop({ default: false }) forceNew: boolean;

  @Getter('facebook/getSyncedPages') syncedPages: StoreFacebookPage[];

  ready: boolean = false;

  showOnReady: boolean = false;

  mounted() {
    this.$store.dispatch('facebook/loadSyncedPages').finally(() => {
      this.ready = true;
      if (this.showOnReady) {
        this.show();
      }
    });
  }

  show() {
    if (!this.ready) {
      this.showOnReady = true;
      return;
    }
    if (this.forceNew || this.syncedPages.length === 0) {
      this.$refs.newModal.show();
    } else {
      this.$refs.existingModal.show();
    }
  }

  handleSyncNewFacebookPageClick() {
    this.$refs.existingModal.hide();
    this.$refs.newModal.show();
  }

  handleSyncExistingFacebookPage(...args: any[]) {
    this.$emit('syncExistingFacebookPage', ...args);
  }

  handleSyncNewFacebookPage(...args: any[]) {
    this.$emit('syncNewFacebookPage', ...args);
  }
}
