





















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { StoreFacebookPage } from '@/shared/store/facebook';
import ModalButton from '@/shared/components/modals/ModalButton.vue';
import SimpleModal from '@/shared/components/modals/SimpleModal.vue';
import SyncFacebookPageAccountDetails from '@/shared/components/modals/social/SyncFacebookPageAccountDetails.vue';

@Component({
  components: {
    ModalButton,
    SimpleModal,
    SyncFacebookPageAccountDetails,
  },
})
export default class SyncExistingFacebookPageModal extends Vue {
  $refs!: {
    modal: SimpleModal,
  };

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('facebook/getSyncedPages') syncedPages: StoreFacebookPage[];

  @Prop({ default: '' }) currentPageId: string;

  localSelectedPageId: string = '';

  forceButtonText: string = '';

  get selectedPageId() {
    if (this.$validPageId(this.localSelectedPageId)) {
      return this.localSelectedPageId;
    }
    if (this.$validPageId(this.currentPageId)) {
      return this.currentPageId;
    }
    if (this.syncedPages.length > 0) {
      return this.syncedPages[0].pageId;
    }
    return '';
  }

  set selectedPageId(value) {
    this.localSelectedPageId = value;
  }

  get selectedPage() {
    if (this.selectedPageId) {
      return this.syncedPages.find((page) => page.pageId === this.selectedPageId);
    }
    return undefined;
  }

  get hasInstagram() {
    return this.selectedPage && this.selectedPage.instagram && this.selectedPage.instagram.length > 0;
  }

  get hasNewSelectedPage() {
    return this.localSelectedPageId && this.localSelectedPageId !== this.currentPageId;
  }

  get buttonDisabled() {
    return !!this.forceButtonText;
  }

  get buttonText() {
    if (this.forceButtonText) {
      return this.forceButtonText;
    }
    if (this.hasNewSelectedPage) {
      if (this.hasInstagram) {
        return 'Use these accounts';
      }
      return 'Use this account';
    }
    return 'Dismiss';
  }

  selectPage() {
    this.forceButtonText = this.buttonText;
    if (this.hasNewSelectedPage) {
      this.$emit('syncExistingFacebookPage', this.selectedPage);
    }
    this.hide();
  }

  show() {
    this.$refs.modal.show();
  }

  hide(trigger?: string) {
    this.$refs.modal.hide(trigger);
  }

  $validPageId(pageId: string) {
    if (pageId) {
      return this.syncedPages.findIndex((page) => page.pageId === pageId) !== -1;
    }
    return false;
  }

  handleShow() {
    this.forceButtonText = '';
    if (!this.localSelectedPageId && !this.currentPageId) {
      this.localSelectedPageId = this.selectedPageId;
    }
  }
}
