import { render, staticRenderFns } from "./EditAdButton.vue?vue&type=template&id=2509a41c&scoped=true&"
import script from "./EditAdButton.vue?vue&type=script&lang=ts&"
export * from "./EditAdButton.vue?vue&type=script&lang=ts&"
import style0 from "./EditAdButton.vue?vue&type=style&index=0&id=2509a41c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2509a41c",
  null
  
)

export default component.exports