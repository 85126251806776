


















































































































































































































import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Creative from '@/shared/components/common/Creative.vue';
import {
  Creative as CreativeType,
  Creative_Instagram_Placement as Placement,
  FacebookPage,
  MemberImage,
  UserImage,
  Copy,
  CreativeCustomization,
} from '@/shared/gen/messages.pisa';
import { validationMixin } from 'vuelidate';
import { maxLength, required } from 'vuelidate/lib/validators';
import FacebookNewsFeedCreative from '@/shared/components/common/FacebookNewsFeedCreative.vue';
import InstagramFeedCreative from '@/shared/components/common/InstagramFeedCreative.vue';
import InstagramStoryCreative from '@/shared/components/common/InstagramStoryCreative.vue';
import ModalButton from '@/shared/components/modals/ModalButton.vue';
import SimpleModal from '@/shared/components/modals/SimpleModal.vue';
import * as imgixFunctions from '@/shared/lib/imgixFunctions';
import MaterialInputField from '@/shared/components/form/MaterialInputField.vue';
import ChangeImageSelectionModal from '@/shared/components/builder/changeImageSelectionModal.vue';
import { BvModalEvent } from 'bootstrap-vue';
import DisabledAdOverlay from '@/shared/components/common/DisabledAdOverlay.vue';
import LoadingBackground from '@/shared/components/common/LoadingBackground.vue';
import { ImageConfigJSON } from '@/shared/store/recommended_ads';

const inputLimit: number = 100;

@Component({
  mixins: [
    validationMixin,
  ],
  components: {
    DisabledAdOverlay,
    Creative,
    FacebookNewsFeedCreative,
    InstagramFeedCreative,
    InstagramStoryCreative,
    MaterialInputField,
    ModalButton,
    SimpleModal,
    ChangeImageSelectionModal,
    LoadingBackground,
  },
  validations: {
    textOne: { required, maxLength: maxLength(inputLimit) },
    textTwo: { required, maxLength: maxLength(inputLimit) },
  },
})
export default class EditAdModal extends Vue {
  $refs!: {
    editAdModal: SimpleModal,
  }

  @Prop({ required: true })
  creative: CreativeType;

  @Prop({ default: () => {} })
  userImage: UserImage;

  @Prop({ default: () => {} })
  memberImage: MemberImage;

  @Prop({ default: () => {} })
  imageConfig: ImageConfigJSON;

  @Prop({ default: '' })
  strategyType: string;

  @Prop({ default: () => {} })
  invalidCopy: Copy;

  @Prop({ default: false, type: Boolean })
  disableImageSelection: boolean;

  @Prop({ default: true })
  canDisableCreative: boolean;

  @Getter('onboarding/getFacebookPage') syncedPage: FacebookPage;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  adEnabled: boolean = true;

  textOne: string = '';

  textTwo: string = '';

  loadedOverride: boolean = false;

  blurBottom: boolean = true;

  blurTop: boolean = true;

  creativeLoaded: boolean = false;

  mixpanelStrategyMapping: { [key:string]: string } = {
    localGroup: 'LocalInterest',
    storyGroup: 'Instagram',
    playableGroup: 'Playable',
    feedbackGroup: 'Feedback',
    newsfeedGroup: 'Facebook',
    nonplayableGroup: 'Streaming',
    correction: 'CreativeCorrection',
  };

  get hasInvalidHeader() {
    if (this.invalidCopy && this.invalidCopy.header && this.adEnabled) {
      return this.textOne === this.invalidCopy.header;
    }
    return false;
  }

  get hasInvalidSubheader() {
    if (this.invalidCopy && this.invalidCopy.subheader && this.adEnabled) {
      return this.textTwo === this.invalidCopy.subheader;
    }
    return false;
  }

  flagLoaded() {
    if (this.imageConfig) {
      const promises: Promise<void>[] = [];
      for (const key of Object.keys(this.imageConfig)) {
        const imageConfigData = this.imageConfig[key as keyof ImageConfigJSON];
        if (imageConfigData) {
          for (const key2 of Object.keys(imageConfigData.urls)) {
            promises.push(new Promise<void>((resolve) => {
              const i = new Image();
              i.onerror = () => {
                resolve();
              };
              i.onload = () => {
                resolve();
              };
              i.src = imageConfigData.urls[key2];
            }));
          }
        }
      }
      Promise.all(promises).then(() => {
        this.loadedOverride = true;
        this.creativeLoaded = true;
      });
    } else {
      this.loadedOverride = true;
      this.creativeLoaded = true;
    }
  }

  @Watch('creative')
  watchCreative() {
    if (this.creativeCustomization) {
      if (this.creativeCustomization.copy) {
        this.textOne = this.creativeCustomization.copy.header ? this.creativeCustomization.copy.header : '';
        this.textTwo = this.creativeCustomization.copy.subheader ? this.creativeCustomization.copy.subheader : '';
      } else if (this.creative.display) {
        this.textOne = this.creative.display.header;
        this.textTwo = this.creative.display.subheader;
      }
      this.adEnabled = this.creativeCustomization.enabled;
    }
  }

  get characterCountPrimary() {
    if (this.textOne.length > inputLimit - 20) {
      return `${this.textOne.length}/${inputLimit}`;
    }
    return '';
  }

  get characterCountSecondary() {
    if (this.textTwo.length > inputLimit - 20) {
      return `${this.textTwo.length}/${inputLimit}`;
    }
    return '';
  }

  get saveChangesButtonDisabled() {
    let disabled = false;
    if (this.creativeCustomization && this.creativeCustomization.copy.showSubheader) {
      disabled = (!(this.textTwo.trim().length > 0) || this.textTwo.trim().length > inputLimit) && this.adEnabled;
    }
    return disabled || ((!(this.textOne.trim().length > 0) || this.textOne.trim().length > inputLimit) && this.adEnabled) || !this.creativeLoaded;
  }

  get isFacebook() {
    return this.creative.network === 'FACEBOOK';
  }

  get callToAction() {
    if (this.isFacebook) {
      return this.creative.facebook.callToAction;
    }
    return this.creative.instagram.callToAction;
  }

  get creativeImage() {
    if (this.userImage) {
      return this.userImage;
    }
    return this.memberImage;
  }

  get imageUrl() {
    if (this.creativeImage.url) {
      const imageUrl = this.creativeImage.url;
      return imgixFunctions.getImgixSizeQuerystring(imageUrl, 300, 300);
    }
    return '';
  }

  get caption() {
    return this.creative.facebook.caption;
  }

  get description() {
    return this.creative.facebook.description;
  }

  get component() {
    if (this.creative.instagram.placement === Placement.STORY) {
      return 'InstagramStoryCreative';
    }
    return 'InstagramFeedCreative';
  }

  get colorPalette() {
    return this.creativeImage.colorPalette;
  }

  get creativeCustomization() {
    return this.creative.customization;
  }

  get headerErrorText() {
    if (!this.$v.textOne.maxLength) {
      return `Must be less than ${inputLimit} characters`;
    }
    return 'Please enter ad text';
  }

  get subheaderErrorText() {
    if (!this.$v.textTwo.maxLength) {
      return `Must be less than ${inputLimit} characters`;
    }
    return 'Please enter ad text';
  }

  get contentOverride() {
    let data = {
      copy: {
        header: this.textOne.trim(),
        subheader: this.textTwo.trim(),
      },
    };

    if (this.imageConfig) {
      data = Object.assign(data, this.imageConfig);
    }
    return data;
  }

  scrollToEdit() {
    const fields = document.getElementById('inputFields');
    if (fields) {
      fields.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  save() {
    let copyChanged = false;
    let imageChanged = false;
    let enabledOrDisabled = false;

    if (this.creativeCustomization.enabled !== this.adEnabled) {
      enabledOrDisabled = true;
    }
    if (this.creativeCustomization.copy.header !== this.textOne.trim()) {
      copyChanged = true;
    }
    if (this.creativeCustomization.copy.showSubheader && this.creativeCustomization.copy.subheader !== this.textTwo.trim()) {
      copyChanged = true;
    }
    if ((this.userImage && this.creativeCustomization.userImageId !== this.userImage.id)
      || (this.memberImage && this.creativeCustomization.memberImageId !== this.memberImage.id)) {
      imageChanged = true;
    }

    if (!this.invalidCopy) {
      if (copyChanged) {
        this.$store.dispatch('recommendedAds/userEditedCopy', this.creative.id);
      }
      let changeType = '';
      if (enabledOrDisabled) {
        changeType = 'AdDisabled';
      } else if (copyChanged && imageChanged) {
        changeType = 'ImageAndCopy';
      } else if (copyChanged) {
        changeType = 'CopyOnly';
      } else if (imageChanged) {
        changeType = 'ImageOnly';
      }
      if (changeType) {
        this.$store.dispatch('mixpanel/track', {
          properties: {
            ad_type: this.mixpanelStrategyMapping[this.strategyType],
            item_changed: changeType,
          },
          action: 'Zire.IndividualEditModalChangesSaved',
        });
      }
    }

    if (copyChanged || imageChanged || enabledOrDisabled) {
      const copy = new Copy({
        header: this.textOne,
        subheader: this.textTwo,
        showSubheader: this.creative.customization.copy.showSubheader,
      });

      const customization = new CreativeCustomization({
        copy,
        enabled: this.adEnabled,
        userImageId: this.userImage ? this.userImage.id : '0',
        memberImageId: this.memberImage ? this.memberImage.id : '0',
      });

      const creative = new CreativeType({
        customization,
        id: this.creative.id,
        network: this.creative.network,
        instagram: this.creative.instagram,
        facebook: this.creative.facebook,
        display: this.creative.display,
        properties: this.creative.properties,
      });
      this.$emit('customized', creative);
    }
    this.$refs.editAdModal.hide();
  }

  toggleAd() {
    this.adEnabled = !this.adEnabled;
  }

  markCreativeLoaded() {
    this.creativeLoaded = true;
  }

  showChangeImageSelection(evt: Event) {
    evt.preventDefault();
    this.$refs.editAdModal.hide('change-image-selection');
    this.$root.$emit('bv::show::modal', 'change-image-selection-modal');
  }

  handleModalHide(e: BvModalEvent) {
    if (e.trigger === 'change-image-selection') {
      this.$root.$once('bv::modal::shown', (evt: BvModalEvent) => {
        if (evt.componentId === 'change-image-selection-modal') {
          this.$root.$once('bv::modal::hidden', (event: BvModalEvent) => {
            if (event.componentId === 'change-image-selection-modal') {
              this.$refs.editAdModal.show();
            }
          });
        }
      });
    } else {
      this.watchCreative();
      this.$emit('closed');
    }
  }

  handleModalShow() {
    this.loadedOverride = false;
    this.creativeLoaded = false;
  }

  handleModalShown() {
    if (!this.invalidCopy) {
      this.$store.dispatch('mixpanel/track', {
        properties: {
          ad_type: this.mixpanelStrategyMapping[this.strategyType],
        },
        action: 'Zire.IndividualEditModalSurfaced',
      });
    }
  }

  delayTouchUntilBlur($v: any) {
    $v.$reset();
  }

  touchElement($v: any) {
    $v.$touch();
  }
}
