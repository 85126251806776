var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleModal',{ref:"editAdModal",attrs:{"modal-id":"edit-ad-modal","size":"lg","allow-scrolling":true,"white":""},on:{"hide":_vm.handleModalHide,"show":_vm.handleModalShow,"shown":_vm.handleModalShown},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"copy"},[_vm._v(" Edit Ad ")])]},proxy:true},(!_vm.isMobile)?{key:"footer",fn:function(){return [_c('div',{staticClass:"edit-ad-modal__footer copy"},[_c('ModalButton',{staticClass:"edit-ad-modal__footer__button",attrs:{"variant":"none","disabled":_vm.saveChangesButtonDisabled,"text":'Save'},on:{"click":_vm.save}})],1)]},proxy:true}:null],null,true)},[_c('div',{staticClass:"edit-ad-modal__body blur-top blur-bottom",class:{
      'btactive': _vm.blurTop && (_vm.isMobile || _vm.isTablet),
      'bbactive': _vm.blurBottom && (_vm.isMobile || _vm.isTablet),
    }},[_c('div',{class:{
        'side-by-side': !_vm.isMobile && !_vm.isTablet,
        'instagram': (!_vm.isMobile && !_vm.isTablet) && _vm.creative.network === 'INSTAGRAM',
        'facebook': (!_vm.isMobile && !_vm.isTablet) && _vm.creative.network === 'FACEBOOK',
      }},[_c('div',{staticClass:"edit-ad-modal__body__creative",on:{"click":_vm.scrollToEdit}},[_c('DisabledAdOverlay',{attrs:{"disabled":!_vm.adEnabled}},[_c('LoadingBackground',{attrs:{"loaded-override":_vm.loadedOverride},on:{"loaded":_vm.markCreativeLoaded}},[(_vm.creative.network === 'DISPLAY')?_c('Creative',{staticClass:"creative-display",attrs:{"creative":_vm.creative.id,"override-content":"","override":_vm.contentOverride},on:{"loaded":_vm.flagLoaded}}):(_vm.creative.network === 'FACEBOOK')?_c('FacebookNewsFeedCreative',{staticClass:"creative-display",attrs:{"primary-text":_vm.textOne,"headline":_vm.textTwo,"call-to-action":_vm.callToAction,"image-url":_vm.imageUrl,"caption":_vm.caption,"description":_vm.description,"synced-page":_vm.syncedPage,"hide-account-details":""},on:{"loaded":_vm.flagLoaded}}):(_vm.creative.network === 'INSTAGRAM')?_c(_vm.component,{tag:"component",staticClass:"creative-display",attrs:{"primary-text":_vm.textOne,"call-to-action":_vm.callToAction,"image-url":_vm.imageUrl,"color-palette":_vm.colorPalette,"synced-page":_vm.syncedPage,"hide-account-details":""},on:{"loaded":_vm.flagLoaded}}):_vm._e()],1),(_vm.strategyType === 'localGroup')?_c('div',{staticClass:"location"},[_c('fa-icon',{attrs:{"icon":['far', 'map-marker-alt']}}),_vm._v(" "+_vm._s(_vm.creative.properties.geo)+" ")],1):_vm._e()],1)],1),_c('div',{staticClass:"edit-ad-modal__body__input-fields",attrs:{"id":"inputFields"}},[(!_vm.disableImageSelection)?_c('b-button',{staticClass:"edit-ad-modal__body__input-fields__image-button",attrs:{"disabled":!_vm.adEnabled},on:{"click":_vm.showChangeImageSelection}},[_c('fa-icon',{attrs:{"icon":['fas', 'image']}}),_vm._v(" Change Image ")],1):_vm._e(),_c('MaterialInputField',{ref:"textOne",staticClass:"edit-ad-modal__body__input-fields__text fade-input",attrs:{"element-id":"text-one","element-ref":"textOne","input-classes":{
            'form-field__input': true,
            'is-invalid is-invalid--mask': (_vm.$v.textOne.$dirty && _vm.$v.textOne.$invalid) || !_vm.$v.textOne.maxLength || _vm.hasInvalidHeader,
          },"type":"text","left-icon":['far', 'text'],"show-left-icon":true,"label":_vm.characterCountPrimary,"label-classes":{
            'form-field__label': true,
            'is_invalid': !_vm.$v.textOne.maxLength
          },"autofocus":false,"placeholder":"Enter Primary Text","show-error":_vm.$v.textOne.$dirty && _vm.$v.textOne.$invalid,"show-right-button":_vm.hasInvalidHeader,"right-button-icon":['fas', 'exclamation-triangle'],"error-text":_vm.headerErrorText,"disabled":!_vm.adEnabled,"autocomplete":"off","autocorrect":"on"},on:{"input":function($event){return _vm.delayTouchUntilBlur(_vm.$v.textOne)},"blur":function($event){return _vm.touchElement(_vm.$v.textOne)}},model:{value:(_vm.$v.textOne.$model),callback:function ($$v) {_vm.$set(_vm.$v.textOne, "$model", $$v)},expression:"$v.textOne.$model"}}),(_vm.creativeCustomization && _vm.creativeCustomization.copy.showSubheader)?_c('MaterialInputField',{ref:"textTwo",staticClass:"edit-ad-modal__body__input-fields__text fade-input",attrs:{"element-id":"text-two","input-classes":{
            'form-field__input': true,
            'is-invalid is-invalid--mask': (_vm.$v.textTwo.$dirty && _vm.$v.textTwo.$invalid) || !_vm.$v.textTwo.maxLength || _vm.hasInvalidSubheader,
          },"type":"text","left-icon":['far', 'text'],"show-left-icon":true,"label":_vm.characterCountSecondary,"label-classes":{
            'form-field__label': true,
            'is_invalid': !_vm.$v.textTwo.maxLength
          },"placeholder":"Enter Secondary Text","show-error":_vm.$v.textTwo.$dirty && _vm.$v.textTwo.$invalid,"show-right-button":_vm.hasInvalidSubheader,"right-button-icon":['fas', 'exclamation-triangle'],"error-notification-classes":"error-notification form-error hide-error-on-focus","error-text":_vm.subheaderErrorText,"disabled":!_vm.adEnabled,"autocomplete":"off","autocorrect":"on"},on:{"input":function($event){return _vm.delayTouchUntilBlur(_vm.$v.textTwo)},"blur":function($event){return _vm.touchElement(_vm.$v.textTwo)}},model:{value:(_vm.$v.textTwo.$model),callback:function ($$v) {_vm.$set(_vm.$v.textTwo, "$model", $$v)},expression:"$v.textTwo.$model"}}):_vm._e(),(_vm.adEnabled && _vm.canDisableCreative)?_c('div',{staticClass:"edit-ad-modal__body__input-fields__disable"},[_vm._v(" Don't want to run this ad? "),_c('a',{attrs:{"href":"javascript:;"},on:{"click":_vm.toggleAd}},[_vm._v("Turn it off")])]):(!_vm.adEnabled)?_c('div',{staticClass:"edit-ad-modal__body__input-fields__disable"},[_vm._v(" Change your mind? "),_c('a',{attrs:{"href":"javascript:;"},on:{"click":_vm.toggleAd}},[_vm._v("Turn this ad back on")])]):_vm._e()],1)]),(_vm.isMobile)?_c('div',{staticClass:"body-save"},[_c('ModalButton',{staticClass:"edit-ad-modal__footer__button",attrs:{"disabled":_vm.saveChangesButtonDisabled,"text":'Save'},on:{"click":_vm.save}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }